import React from 'react'
import './TermsAndConditions.scss'


const TermsAndConditions = () => {
    return (
        <div>
            <section className="terms-of-use">
                <div className="container">
                    <div className="mt-10">
                        <div className="text-center col-xs-12">
                            <h2> Terms of Service </h2>
                            <hr style={{ border: "solid 1px #fbb000", marginTop: 0, marginBottom: 0, width: "15%" }} />
                        </div>
                        <div className="clearfix"></div>
                        <div className="mt-3">
                            <p className="sub-title"><strong>Welcome to the Lynk Platform (the <strong><u>"Lynk Platform"</u></strong>).</strong></p>

                            <p><strong>The Lynk Platform is operated by Lynks Logistics Ltd.</strong></p>

                            <p className="sub-title">The Lynk Platform provides every user with a platform where they can avail of specific carriage services which are offered by us. The Lynk Platform may be presently accessed on our website,<a href="https://www.lynk.co.in"> www.lynk.co.in</a>, the Lynk Mobile Application or by calling us on 1800-258-2424, our toll free number, where our team will make a booking for you on the Lynk Platform.</p>

                            <p>Use of the Lynk Platform is governed by the following terms and conditions (<strong>“Terms of Service”</strong>). Please review the Terms of Service carefully. The use of any other product or service offered by Lynks Logistics Ltd (including access to its websites) shall be governed by separate terms and conditions and are not covered by these Terms of Service. </p>

                            <p>By using the Lynk Platform, you are agreeing to comply with and be bound by the Terms of Service. If you do not agree to the Terms of Service, you should not use the Lynk Platform.</p>

                            <p>The terms “we” and “us” refers to Lynks Logistics Ltd and its affiliates and any successor entity, and the term “you” refers to you, the user of the Lynk Platform.</p>

                            <p className="sub-title"><u>What do we provide on the Lynk Platform</u></p>

                            <p>We operate a goods transport agency including through the Lynk Platform and any other related websites, toolbars, widgets, applications, mobile apps, or other distribution channels (including application programming interfaces) we may, from time to time, operate. Users of the Lynk Platform and our carriage services would be permitted to send consignments through our services to their selected receivers / consignees. From time to time we may also provide value added services and other services through the Lynk Platform. You may use the Lynk Platform for your personal and business use or for business purpose of the business entity or organisation that you represent.</p>

                            <p>You, as the consignor, shall be required to comply with all applicable laws when engaging our carriage services for delivery of your selected consignment to the receive / consignee. You understand that by using the Lynk Platform you are entering into a contract of carriage for the consignment with us and these terms of service shall apply to our arrangement wherein we provide you logistics and carriage services as a goods transport agency. We reserve the right to provide our carriage services and other services to you, at our sole discretion, and upon the terms of service set out here, or other terms we may specifically communicate from time to time. We are not responsible for any loss caused to you arising out of the carriage services being provided by your use of the Lynk Platform. We shall issue a goods receipt / consignment note, based on these terms of service. </p>

                            <p className="sub-title"><u>Acceptance of the Terms</u></p>

                            <p>By using the Lynk Platform, you are agreeing to comply with and be bound by the Terms of Service.</p>

                            <p>Your use of the Lynk Platform is subject to the Terms of Service, which may be updated from time to time without notice to you.</p>

                            <p>Your acceptance of the Terms of Service is valid, binding and constitutes the entire agreement between us (irrespective of whether you are a user of or visitor to the Lynk Platform). The Terms of Service supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the Lynk Platform, and the services that are provided to you through the Lynk Platform.</p>

                            <p className="sub-title"><u>Eligibility</u></p>

                            <p>By using the Lynk Platform, you represent and warrant that </p>
                            <ul>
                                <li type="a">you are fully able, competent and authorised to enter into the terms, conditions, obligations, representations and warranties set forth in these Terms of Service; </li>
                                <li type="a">all registration information you submit is truthful and accurate;</li>
                                <li type="a">you will maintain the accuracy of such information; </li>
                                <li type="a">have attained the age to legally accept these Terms of Service as per the laws applicable to you and/or at your location, or the age of 18 years, whichever is higher;</li>
                                <li type="a">all details you provide us regarding your consignment shall be true and accurate, and you acknowledge that we are not required to independently verify the same when issuing the goods receipt / consignment note / goods forwarding note; and </li>
                                <li type="a">your use of the Lynk Platform does not violate any applicable law or regulation. Use of the Lynk Platform is void where prohibited.</li>

                            </ul>
                            <p>You are not eligible to use the Lynk Platform if you are our competitor, or if you operate a similar business or platform. The Lynk Platform is available only to genuine users who are accessing it for their personal or business purposes. </p>

                            <p className="sub-title"><u>Registration and Your Account</u></p>

                            <p>You can create an account with us by registering on the Lynk Platform. The registration of your account on the Lynk Platform is subject to you satisfying the Terms of Service at all times. </p>

                            <p>If we have reasonable grounds to suspect violation of these Terms of Service or that registration information you have provided is untrue, inaccurate, outdated, or incomplete, we may terminate your account and refuse current or future use of any or all parts of the Lynk Platform. </p>

                            <p className="sub-title"><u>Security and Access of Your Account</u></p>

                            <p>You are solely responsible for maintaining the confidentiality of your username and password and for all activities carried out under your account. We are not responsible for any loss or damage to you or any third party that may be incurred as a result of any unauthorised access and/or use of your account, or otherwise.</p>

                            <p>You agree not to use the account, username or password of another user (unless validly authorised) at any time or to disclose your password to any third party or do anything else that might jeopardize the security of your account. You agree to notify us immediately of any unauthorised use of your account.</p>

                            <p>You agree not to access or use the Lynk Platform in any manner that could damage, disable, overburden, or impair any computer system, network, website, the Lynk Platform or any other product, service or website operated by us or any other person. You also agree not to interfere with or attempt to gain unauthorised access to any parts of the Lynk Platform or any accounts, computer systems or networks, etc. </p>

                            <p>You agree not to use any robot, spider, scraper or other automated means to access the Lynk Platform or any other product, service or website operated by us or any other person. We may, at our sole discretion, permit the use of distribution channels such as application programming interfaces for the Lynk Platform.</p>

                            <p className="sub-title"><u>Your Content, User Data and Privacy</u></p>

                            <p>We are concerned about your privacy and have developed a policy to address privacy concerns. Any personal information collected on the Lynk Platform may be accessed and stored globally and will be treated in accordance with our Privacy Policy which can be accessed here <a href="/privacy-policy" target="_blank"> http://www.lynk.co.in/privacy-policy </a></p><p>

                            </p><p>Any content you create or store on the Lynk Platform (collectively, “Content”) remains yours. You are solely responsible for your Content and the consequences of its transmission. You are further responsible for ensuring that you do not accidentally make any private Content publicly available. Any Content that you may receive through the use of the Lynk Platform from your is provided to you ‘AS IS' for your information and personal use only and you agree not to use or otherwise exploit such Content for any purpose without the express written consent of the person who owns the rights to such Content. You represent and warrant that the Content does not violate any applicable law, rule or regulation or third party rights. You agree to indemnify us against any claims against us arising out of your Content.</p>

                            <p>You agree not to use, display or share your Content or any data we provide you in a manner inconsistent with the Terms of Service or any applicable law, rule or regulation. We are not under any obligation to keep back-up copies of the Content once your account is deleted. We make no guarantee that Content will be safely stored on the Lynk Platform. To be safe, you should independently back-up your Content, to the extent permitted herein and by applicable laws, rules and regulations.</p>

                            <p className="sub-title"><u>Subscription to Beta Services</u></p>

                            <p>We may offer certain services on the Lynk Platform as closed or open beta services (“Beta Services”) during the testing and evaluation period. You agree that we have the sole authority and discretion to determine the period of time for testing and evaluation of Beta Services. We reserve the right to fully or partially discontinue, at any time and from time to time, temporarily or permanently, any of the Beta Services with or without notice to you. We also reserve the right to make the Beta Services available for a subscription fee. </p>

                            <p className="sub-title"><u>Pricing</u></p>

                            <p>Information on the indicative pricing and charges for all services offer on the Lynk Platform is available on the Lynk Mobile App or will be informed by our Call Center representative at the time of booking. The actual pricing and charges will be known to you once we have the information regarding your consignment and the destination and any other relevant information. We reserve the right to change the pricing without notice to you since it is dynamic and pricing is based on several factors depending on the nature of your consignment. We also reserve the right to customise the pricing for different users based on specific requirements applicable to each booking.</p>

                            <p className="sub-title"><u>Payments, Lynk Wallet, Invoicing and Taxes</u></p>

                            <p>You shall pay the charges and any fee or levy presently payable or hereinafter imposed by the law or required to be paid for availing the services. You may choose to make payment for the services availed by any of the following methods:</p>
                            <ul>
                                <li type="a"><p><u>Cash payment:</u>Where you opt to pay by cash, you shall make the cash payment upon delivery of your consignment to the person / driver of the vehicle carrying your consignment, as nominated by Lynk. You are required to make payment of the price communicated to you, and we through the person / driver collecting your consignment may be entitled to withhold the consignment or take any further action against you if you fail to make payment as per the agreed terms. </p></li>
                                <li type="a"><p><u>Online payment:</u>Online payment for the services shall be made to us at the time book our services, and can be made either via credit card / debit card / cash cards, net banking, NEFT, RTGS, IMPS, etc., as may be processed by independent payment service providers, through other banking channels, or under the advance payments scheme operated by us only for users of the Lynk Platform as a closed group e-wallet (the <strong>“Lynk Wallet”</strong>). In the event the payment cannot be accepted through the online payment methods, you will have to make a cash payment of the charges to us by directly making payment to the person / driver collecting your consignment.</p></li>
                            </ul>
                            <p>We may offer discounts and consequent deductions in the charges to be paid by you at our sole discretion. Any requests for refunds will be reviewed in light of the refund policy applicable. </p>

                            <p>The Lynk Wallet is a closed ended scheme offered only to users of the Lynk Platform, where you as a user will have the option to make an advance payment to us which will be adjusted against the charges incurred by you on the Lynk Platform, for services which you may request now or in the future. You may make advance payments by the online method or by any other method designated by Lynks Logistics Ltd from time to time, and the amount so paid in advance will reflect on your user page. No interest shall be payable for the advance amounts paid to us. The advance amounts will be adjusted against charges you may incur pursuant to your use of the Lynk Platform and the amount is non-refundable and any refunds will only considered in accordance with our refund policy (explained below).</p>

                            <p>We will generate an invoice which will be sent across to you by e-mail upon the completion of the services. The invoice shall be indicative of all applicable charges including but not limited to the surcharge, toll charges, right time charge, wait time charge, service tax or any other fees / taxes / charges we may incur in the provision of the services.</p>

                            <p>You are responsible for paying all fees and applicable taxes associated with the services in a timely manner with a valid payment method. Your use of the Lynk Platform and/or the services we provide you may be subject to taxes, duties and fees payable in India and/or your location. You shall be solely responsible for payment of all such taxes, duties and fees and the charges provided by us are exclusive of any taxes, duties and fees. In certain cases, we may have the obligation to pay taxes, duties and fees arising out of our arrangement and such taxes, duties and fees shall be charged to you in addition to any payments you make for the services. If the payment method specified by you fails or your account is past due, then:</p>
                            <strong>
                                <ul>
                                    <li type="a" className="setbold">you agree to pay all amounts due on your account upon demand and reimburse us for all reversals, charge-backs, claims, fees, fines, penalties and other liability incurred by us (including costs and related expenses) that were caused by or arising out of payments that you authorised or accepted,</li>
                                    <li type="a" className="setbold">
                                        we may collect fees owed using other collection mechanisms, if it remains unpaid; and
                                    </li>
                                    <li type="a" className="setbold">we reserve the right to either suspend or terminate your account or any pending services, including deletion of your account.</li>
                                </ul>
                            </strong>
                            <p>You agree to submit any disputes regarding any charge to your account in writing to us within thirty (15) days of such charge, otherwise such dispute will be waived and such charge will be final and not subject to challenge.</p>


                            <p className="sub-title"><u>Insurance and Liability</u></p>

                            <p>We shall not incur any liability to you for providing you with the services and access to the Lynk Platform. Notwithstanding this, in the event we are ever held liable for any loss suffered by you, our liability shall not exceed the fees received by us for the particular services you have selected on the Lynk Platform. No liability shall be incurred by us in the event of a force majeure event resulting in the disruption of the services.</p>

                            <p>You will be required to indemnify us from and against and in respect of any or all liabilities, losses, charges and expenses (including legal fees and costs) claims, demands, actions and proceedings which we may incur or sustain directly or indirectly from or by any reason of or in relation to the use or proposed use of the Lynk Platform or a breach of any representations and warranties made by you, and shall pay such sums on demand by us.</p>

                            <p>You warrant, undertake and guarantee to us that: </p>

                            <ul>
                                <li>The contents of the consignment have been properly described at the time of booking on the Lynk Platform, and such a consignment note is complete in all respects and the documents as required for the carriage / consignment including invoices, permits are enclosed along with the booking.</li>
                                <li>That the goods being consigned by you are not Prohibited Items, prohibited for carriage by any law, rule, regulation of India and/or are not restricted by the applicable regulations and neither you nor the consignee is a person or organisation with whom we or you may not legally trade under any applicable laws or regulations.</li>
                                <li>That all statements and information and documents provided by you relating to the consignment will be true and correct and you acknowledge that in the event that you make untrue or fraudulent statement about the consignment or any of its contents, you would risk a civil claim and/or criminal prosecution the penalties for which may include forfeiture and sale. You agree to indemnify us and hold us harmless from any claims that may be brought against us or our agents arising from the information provided by you.</li>
                                <li>The drivers / delivery personnel are authorised to deliver the goods at the address mentioned at the time of booking and without prejudice to the foregoing it is expressly agreed that they shall be conclusively presumed to have delivered the goods in accordance with booking.</li>
                                <li>You have provided the accurate declaration with respect to the type of goods and the value of the consignment and you will provide any special equipment we may need to load or unload the consignment on or off the vehicles.</li>
                                <li>You will provide accurate information pertaining to the consignment, and we shall not be required to independently verify the consignment, and we will issue the goods receipt / consignment note / goods forwarding note based on the information provided by you.</li>
                                <li>All applicable laws and regulations have been complied with by you.</li>
                            </ul>

                            <p className="sub-title"><u>Refunds</u></p>

                            <p>We generally do not refund any amounts received from your for any services delivered through the Lynk Platform. However, in cases or erroneous transactions and other similar exceptional cases, you can write to us at contact@lynk.co.in with all the relevant details, and we shall consider any refund on a case-to-case basis, at our sole discretion.</p>

                            <p className="sub-title"><u>Prohibited Activities</u></p>

                            <p>You agree not to use the Lynk Platform for illegal, harmful, misleading, fraudulent or other malicious purposes or to post, disseminate or communicate any unlawful, defamatory, obscene, lewd, excessively violent, harassing, sexually explicit or otherwise objectionable subject matter or for the transmission of material that contains viruses or other malicious code, or that which infringes or may infringe intellectual property or other rights of another. You agree not to use the Services for the transmission of “junk mail”, “spam”, “chain letters”, “phishing” or unsolicited mass distribution of email. You agree to indemnify us for any liability on us which may arise due to your misuse of the Lynk Platform.</p>

                            <p>Despite these prohibitions, content communicated by other users of the Lynk Platform may contain inaccurate, inappropriate, offensive or sexually explicit material, products or services, and we assume no responsibility or liability for this material.</p>

                            <p>Without assuming any obligation to do so, we may delete any Content or suspend any account associated with it, that we have reasonable grounds to believe violates the Terms of Service, any applicable law, or that may be offensive or illegal, or violate the rights, harm, or threaten the safety of any person.</p>


                            <p className="sub-title"><u>Inactive Accounts Policy</u></p>

                            <p>We reserve the right to disable or deactivate unpaid user accounts that are inactive for more than [180] days, at our sole discretion. In the event of such termination, all data associated with such account may be deleted. We will provide you prior notice of such termination by email.</p>

                            <p className="sub-title"><u>Monitoring</u></p>

                            <p>We reserve the right (but have no obligation) to monitor all activities on your account to ensure compliance with the Terms of Service and applicable law.</p>

                            <p className="sub-title"><u>Service Communication</u></p>

                            <p>We may contact you with service-related communication, from time to time, including any notices required by law, in lieu of communication by postal mail or important notices pertaining to our arrangement. We shall use the contact information provided at the time of registration and you should ensure that they are up-to-date. We may also use contact information to send you other messages, such as updates, user communications, newsletters, changes to features of the Lynk Platform, or our offers.</p>

                            <p className="sub-title"><u>Third Party Links and Interaction</u></p>

                            <p>Additionally, we may provide hyperlinks though the Lynk Platform, or any other form of link or redirection of your connection to other sites (<strong>“Third Party Sites”</strong>). Links to these Third Party Sites are provided solely for your convenience and in no way does the inclusion of any link on the Site or Services imply our affiliation or endorsement of the linked site, their business practices (including their privacy policies) or any information therein. We expressly disclaim responsibility for the accuracy, quality, legality, nature, availability or reliability of Third Party Sites linked to by or through the Lynk Platform.</p>

                            <p>You release us, our officers, employees, agents and successors from claims, demands and damages of every kind or nature arising out of or related to any disputes with other users and / or third parties. </p>

                            <p className="sub-title"><u>Intellectual Property</u></p>

                            <p>You acknowledge and agree that we and our licensors retain ownership of all intellectual property rights (including applicable copyrights, trademarks and other proprietary rights) of any kind on the Lynk Platform, and our websites, emails, platforms, mobile sites, applications, other products and/or services. We are not granting any license to you under any of those intellectual property rights by virtue of these Terms of Service. You further acknowledge and agree that we retain ownership and control over the “look and feel” and substance of our tools, widgets, buttons, applications and the like. We reserve all rights that are not explicitly granted to you in under these Terms of Service.</p>

                            <p>Our trademarks, logos, service marks, images, trade names, designs, page headers, button icons, scripts and other distinctive branding features constitute our intellectual property and may not be copied, imitated, or used, in whole or in part. You agree not to infringe any intellectual property rights when you use the Lynk Platform and that you will indemnify us for any claims which may arise on us in this regard.</p>

                            <p>We have invested substantial time and effort into developing the Lynk Platform and it contains our own or licensed intellectual property. You further agree not to breach our intellectual property rights or attempt to reverse-engineer or discover the source code (by any means) of the Lynk Platform. You agree that any infringement of these restrictions will cause us irrepairable loss.</p>

                            <p className="sub-title"><u>Disclaimer of Warranties</u></p>
                            <p>We do not warrant that:</p>
                            <ol>
                                <li type="a">the Lynk Platform will meet your specific requirements;</li>
                                <li type="a">the services on the Lynk Platform will be uninterrupted, timely, secure, or error-free, or be available to you at all times;</li>
                                <li type="a">the services that may be obtained from the use of the Lynk Platform will be accurate or reliable;</li>
                                <li type="a">the quality of any products, services, information, or other material purchased or obtained by you through the Lynk Platform will meet your expectations; and </li>
                                <li type="a">any errors on the Lynk Platform will be corrected.</li>
                            </ol>

                            <p className="sub-title"><u>Limitation of Liability</u></p>

                            <p>You expressly understand and agree that Lynks Logistics Ltd, its affiliates and subsidiaries, and their respective officers, directors, agents, co-branders or other partners, and employees shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if we have been advised of the possibility of such damages), resulting from: </p>
                            <ol>
                                <li type="a">the use or the inability to use the Lynk Platform; </li>
                                <li type="a">the cost of procurement of substitute products and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the Lynk Platform; </li>
                                <li type="a">unauthorized access to or alteration of your transmissions or data; </li>
                                <li type="a">statements or conduct of any third party on the Lynk Platform; </li>
                                <li type="a">or any other matter relating to the Lynk Platform.</li>
                            </ol>

                            <p>Your sole and exclusive remedy  for any dispute regarding the Lynk Platform, with us or any of the persons detailed above shall be termination of your relationship with us. In no event shall our entire liability to you in respect of any Service, whether direct or indirect, exceed the fees paid by you towards the services in question. </p>

                            <p>We shall not be liable for any claims which may arise out of our arrangement and your only recourse for any loss you may suffer will be under the applicable insurance policy you may take for the consignment of the goods. You shall indemnify us against any claims which may arise out of your interactions with your customers or actions of your customers pursuant to your use of the Lynk Platform. The Lynk Platform does not conduct any independent verification and does not undertake any liability arising due to your use of the Lynk Platform.</p>

                            <p>Notwithstanding anything contained in the terms of service or the goods receipt / consignment note / goods forwarding note, you acknowledge and agree that we shall not incur any liability for any damages of any kind arising from the use of the service provided by us, including, but not limited to direct, indirect, incidental, punitive, and consequential damages. Further, we shall not be responsible for any loss you may suffer for any reason whatsoever, including due to non-compliance with applicable law or these terms of service, or arising out of any event beyond our control, including but not limited to, acts of god including earthquakes, cyclones, storms, flooding, fire, disease, fog, snow or frost or other natural calamities or disasters, war, accidents, acts of public enemies, strikes, embargoes, perils of the air, local disputes or civil commotions, national or local disruptions in transportation networks and mechanical problems to modes of transport or machinery, latent defects or inherent vice in the contents of the consignment, criminal acts of third parties such as theft and arson, etc. </p>

                            <p>If the receiver / consignee refuses to accept delivery, we will try to contact you and discuss the appropriate action, and if we are not able to contact you then we reserve the right to dispose of the consignment in the appropriate manner. You agree to pay us any costs we incur in forwarding, disposing of or returning the consignment and our charges, if any.</p>

                            <p className="sub-title"><u>Indemnification</u></p>

                            <p>You agree to indemnify, defend, and hold harmless Lynks Logistics Ltd, its affiliates and subsidiaries, and their respective officers, directors, agents, co-branders or other partners, and employees and its agents from and against any claim, demand, loss, damage, cost, or liability (including reasonable attorneys' fees) arising out of or relating to:</p>
                            <ol>
                                <li type="a">claims arising out of your use of the Lynk Platform, or any damage or loss caused due to your consignment; </li>
                                <li type="a">your use or misuse of the Lynk Platform;</li>
                                <li type="a">your connection to and use of the Lynk Platform;</li>
                                <li type="a">your breach or alleged breach of these Terms of Service; or</li>
                                <li type="a">your violation of any applicable law and/or rights of a third party.</li>
                            </ol>


                            <p className="sub-title"><u>Changes to the Lynk Platform</u></p>

                            <p>We reserve the right at any time (and from time to time) to modify, suspend, or discontinue providing the Lynk Platform or any part thereof with or without notice. We will not be liable to you or to any third party for any modification, suspension or discontinuance of the Lynk Platform.</p>

                            <p className="sub-title"><u>Amendment of these Terms of Services</u></p>

                            <p>We may amend, modify, change, add or remove portions of these Terms of Service at any time without notice to you. The latest Terms of Service will be posted at this URL, and you should review the Terms of Service each time before you use the Lynk Platform. By your using the Lynk Platform, you are presumed to have notice of the latest version of the Terms of Service.</p><p>

                            </p><p className="sub-title"><u>Term and Termination</u></p>

                            <p>You may terminate your account and end your use of the Lynk Platform at any time. We have the right (at our sole discretion and without any liability to you) for any reason to:</p>
                            <ol>
                                <li type="a">delete, disable or deactivate your account, block your email or IP address, or otherwise terminate your access to or use of the Lynk Platform;</li>
                                <li type="a">remove and discard any Content within any account or anywhere on the Lynk Platform; or (c) shut down an account, with or without notice.</li>
                            </ol>

                            <p>If you terminate your account, we will have no obligation to refund you any fees you may have paid. Upon deactivating your account, these Terms of Service terminate and your access rights to the Lynk Platform immediately cease to exist. Our rights survive the termination of these Terms of Service.</p>

                            <p className="sub-title"><u>Governing Law and Dispute Resolution</u></p>

                            <p>These Terms of Service shall be governed by the laws of the Republic of India without giving effect to any principles that may provide the application of the law of another jurisdiction. </p>

                            <p>Any controversy or claim arising out of or relating to these Terms of Service shall be settled by binding arbitration in accordance with the requirements of the Arbitration and Conciliation Act, 1996 of India. The venue of the arbitration shall be Chennai, India and the sole arbitrator shall be appointed by us. The decision of the sole arbitrator shall be final and unappealable. Notwithstanding the foregoing, we may seek injunctive or other equitable relief to protect our rights in any court of competent jurisdiction. Subject to the foregoing, the Courts in Chennai shall have exclusive jurisdiction over all matters touching upon this Terms of Service.</p>

                            <p className="sub-title"><u>General</u></p>

                            <p>A failure on our part to exercise or enforce any right or provision of these Terms of Service does not constitute a waiver of such right or provision. If any provision of these Terms of Service is found by a court of competent jurisdiction to be invalid, it shall not invalidate the rest of the Terms of Service which shall remain in full force and effect, and we shall try to give effect to the intention as reflected in the provision. We may assign the agreement between us pursuant to the Terms of Service in whole or in part in its sole discretion without your consent and without notice. Nothing in this Agreement shall prevent us from complying with the law and applicable regulations.</p>

                            @*PACKERS AND MOVERS TERMS OF USE*@
                            <div className="text-center col-xs-12">
                                <h4 style={{ fontSize: "1.50em", fontWeight: 300, marginTop: "10px", marginBottom: "5px" }}> Lynk2Win:Terms & Conditions: </h4>
                            </div>
                            <ol>
                                <li>This contest is open for registered  Lynk users.</li>
                                <li>Lynk Logistics reserves all rights to the contest.</li>
                                <li>Multiple entries are allowed, winners will be selected through a lucky draw.</li>
                                <li>Apple is not involved in any way with the contest or sweepstakes.</li>
                            </ol>
                            <div className="text-center col-xs-12">
                                <h4 style={{ fontSize: "1.50em", fontWeight: 300, marginTop: "30px", marginBottom: "30px" }}> Lynk Move : Specific Terms of Service </h4>
                            </div>

                            <p className="sub-title"><u>The Service</u></p>

                            <p>These terms of service shall also govern ‘Lynk Move'. Lynk Move is a beta service offered on the Lynk Platform whereby Lynk connects you with providers of moving and packing services.The services will also include packaging material, along with transportation.You will only be allowed to book this service 48 hours before the desired date and time of moving your residence/office.Upon receipt of your request, Lynk will share the same with a service provider who provides moving and packing services and the moving and packing services will be provided by the service provided to you.</p>

                            <p className="sub-title"><u>Pricing</u></p>

                            <p>Information on the pricing for Lynk Move services will be informed by our Call Center representative at the time of booking. We reserve the right to change the pricing without notice to you since it is based on several factors depending on the nature of your consignment. You shall also be expected to make an advance payment at the time of booking which will be adjusted against the final amount payable for using Lynk Move. You will be required to make payment of any balance amount to the service provider upon delivery of your consignment to the person/driver of the vehicle carrying your consignment. Lynk will send to you an invoice for the same within forty-eight hours to the email ID given by you at the time of registration on this Lynk Platform.</p>

                            <p className="sub-title"><u>Cancellation Policy</u></p>

                            <p>If you cancel your booking before twenty-four (24) hours of the time selected by you for the team to report at the premises, then you shall be entitled to a refund of the entire amount paid as advance. If you choose to cancel your booking within twenty-four (24) hours of the of the time selected by you for the team to report at the premises, then Lynk shall not return / refund the advance paid by you and shall treat the same as a cancellation fee, unless otherwise returned or refunded at our sole discretion.</p>

                            <p className="sub-title"><u>Refund Policy</u></p>

                            <p>If you believe that you have been wrongly charged a cancellation fee, please write to us <a href="/contactus" target="_blank">http://www.lynk.co.in/contactus</a> and we shall consider a refund on a case-to-case basis, at our sole discretion.</p>

                            <p className="sub-title"><u>Disclaimers</u></p>

                            <p>You understand that Lynk is merely an intermediary which operates the platform on which you can seek the Lynk Move services from independent service providers who are also registered on the Platform. Lynk does not provide any warranties in relation to these services or the service providers and their representatives, and will not be liable for any claims in this regard. Lynk Move is presently a beta service and Lynk may restructure or revise these terms and the nature of the services from time to time. You are required to review these terms of use before using the platform for these services. By using the Lynk Platform for the Lynk Move services you understand and acknowledge that Lynk shall not be liable to you for any matter arising out of the use of the services, and Lynk expressly disclaims any and all matters which may arise out of your use of the Lynk Move services.</p>

                            <p className="sub-title"><u>Lynk Outstation</u></p>

                            <p>For all Outstation Trips taken through Lynk, you, as consignor, declare that the goods transported in the trip do not include illegal, banned, breached, violative, harmful commodity/commodities or anything of that nature, and further declare that the ownership of the goods does not lie with Lynks Logistics Limited or its aggregators/drivers.</p>

                            <p className="sub-title" style={{ marginTop: "50px" }}><u>Questions and Suggestions</u></p>

                            <p>If you have questions, suggestions, or require to contact us as per these Terms of Service, or wish to make a complaint, please complete a feedback form at <a href="/contactus" target="_blank">http://www.lynk.co.in/contactus</a>   or you can contact us at:</p >

                            <address style={{ marginLeft: "20px" }}>
                                108-A, V M Street, 4th, Floor,<br />
                                DR RADHAKRISHANAN SALAI,<br />
                                Mylapore - 600004.<br /><br />

                                Contact Phone: 1800-258-2424<br /><br />


                                Last updated: 15/11/2016

                            </address>

                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default TermsAndConditions
