import React from 'react'
import underline from '../../../assets/images/underline.svg'
import lynk_vs_others_table from '../../../assets/images/table-design.svg';
import lynk_vs_others_table_responsive from '../../../assets/images/table-design-responsive.svg';
import './LynkVsOthers.scss'

const LynkVsOthers = () => {
    return (
        <div className='lynk-vs-others'>
            <div className='lynk-vs-others-text heading-text short'>
                <p>The only channel Brands need, to <span>reach General Trade<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='lynk-vs-others-text-underline' width="100%" /></span></p>
            </div>
            <img src={lynk_vs_others_table} alt="" width="100%" />
            <img src={lynk_vs_others_table_responsive} alt="" width="100%" />
        </div>
    )
}

export default LynkVsOthers