import React from 'react'

const Contact = ({ city }) => {
    return (
        <div className='contact-wrap'>
            <div className='heading'>{city.city || "-"}</div>
            <div className='contact'>
                <div>
                    <div>{city.stores}K+</div>
                    <div>Stores Covered</div>
                </div>
                <div>
                    <div>{city.centers}+</div>
                    <div>Fulfilment Centers</div>
                </div>
                <div>
                    <div>{city.brands}+</div>
                    <div>Brands</div>
                </div>
            </div>
        </div>
    )
}

export default Contact