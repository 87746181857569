import React from 'react'
import underline from '../../../assets/images/underline.svg'
import './Brands.scss'

const Brands = () => {

    const brandlist = require.context('../../../assets/images/Brands', true);

    const loadImage = (image_name) => {
        try {
            return brandlist(image_name);
        }
        catch (err) {
            console.log(err);
        }
    }

    return (
        <div className='brands-wrap'>
            <div className='brands-text heading-text short'>
                <p>Trusted By <span>Many<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='brands-text-underline' width="100%" /></span></p>
            </div>
            <div className='brandlist'>
                {brandlist.keys().map(b =>
                    <div key={b}>
                        <img src={loadImage(b)} alt={b} key={b} height="90px" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default Brands