export const NAV_LINKS = [
    { navLinkId: 'For Brands', url: "/", scrollToId: 'brands' },
    { navLinkId: 'Supply Chain', url: "/", scrollToId: 'supplyChain' },
    { navLinkId: 'Kirana Credit', url: "/", scrollToId: 'kiranaCredit' },
    { navLinkId: 'Join Us', url: "/joinus" },
    { navLinkId: 'Contact', url: "/contactus" }
]

export const CITY_LIST = [
    {
        "id": "TN",
        "state": "Tamil Nadu",
        "city": "Chennai",
        "coordinates": [
            80,
            12.5
        ],
        "latlng": {
            lat: 13.067439, lng: 80.237617
        },
        "name": "Shivshankar",
        "address": "2nd Floor, 108A, Venkatachala Mudali Street, Dr. Radha Krishnan Salai, Mylapore, Chennai-600004",
        "mobile": "9940253442",
        "email": "shivshankar.k@lynk.co.in",
        "stores": "25",
        "centers": "5",
        "brands": "8"
    },
    {
        "id": "GJ",
        "state": "Gujarat",
        "city": "Ahmedabad",
        "coordinates": [
            72.5,
            22.5
        ],
        "latlng": {
            lat: 22.949490, lng: 72.583891
        },
        "name": "Shobhit Sahai",
        "address": "Plot No. 2 107/A NIDC Near Bhagar Dhangal Lambha Road, Ambha Narol, Ahmedabad - 382405",
        "mobile": "9970050381",
        "email": "shobhitsahai@lynk.co.in",
        "stores": "8",
        "centers": "1",
        "brands": "2"
    },
    {
        "id": "KA",
        "state": "Karnataka",
        "city": "Bangalore",
        "coordinates": [
            77.6,
            12.5
        ],
        "latlng": {
            lat: 13.003572, lng: 77.634554
        },
        "name": "Ankit Sharma",
        "address": "N.33/5, 2nd Floor, Maruti Seva Nagar, Banaswadi Main Road , Bangalore - 560033",
        "mobile": "9983881223",
        "email": "ravi.r@lynk.co.in",
        "stores": "8",
        "centers": "2",
        "brands": "5"
    },
    {
        "id": "MH",
        "state": "Maharashtra",
        "city": "Pune",
        "coordinates": [
            73.8,
            18
        ],
        "latlng": {
            lat: 18.521124, lng: 73.773113
        },
        "name": "Shobhit Sahai",
        "address": "321, Patil Nagar, DSK Ranwara Road Pune Bavdhan (BK) 411021",
        "mobile": "9970050381",
        "email": "shobhitsahai@lynk.co.in",
        "stores": "4",
        "centers": "1",
        "brands": "4"
    },
    {
        "id": "MH",
        "state": "Maharashtra",
        "city": "Mumbai",
        "coordinates": [
            72.9,
            18.5
        ],
        "latlng": {
            lat: 19.196365, lng: 72.858616
        },
        "name": "Shobhit Sahai",
        "address": "Lynks Logistics, Ground Floor E Wing, Bonanza Industrial Estate, Ashok Chakravatri Road, Kandivali East Mumbai - 400101",
        "mobile": "9970050381",
        "email": "shobhitsahai@lynk.co.in",
        "stores": "32",
        "centers": "6",
        "brands": "10"
    },
    {
        "id": "TS",
        "state": "Telangana",
        "city": "Hyderabad",
        "coordinates": [
            79,
            17
        ],
        "latlng": {
            lat: 17.426046, lng: 78.540073
        },
        "name": "Sanjiv Khowala",
        "address": "Limited, 12-13-536, Lane No 10, Street No 14, Nagarjuna Nagar, Tarnaka, Circle 18, Hyderabad",
        "mobile": "8697718212",
        "email": "sanjivkhowala@lynk.co.in",
        "stores": "20",
        "centers": "2",
        "brands": "8"
    },
    {
        "id": "WB",
        "state": "West Bengal",
        "city": "Kolkata",
        "coordinates": [
            88,
            22
        ],
        "latlng": {
            lat: 22.509660, lng: 88.418653
        },
        "name": "Dipak Gandhi",
        "address": "Holding No. 832, Mouza - Karimpur, Nazirabad, Kolkata - 700150",
        "mobile": "8017299263",
        "email": "dipak.g@lynk.co.in",
        "stores": "12",
        "centers": "2",
        "brands": "6"
    },
    {
        "id": "DL",
        "state": "Delhi",
        "city": "Delhi",
        "coordinates": [
            77.1,
            27.5
        ],
        "latlng": {
            lat: 28.710380, lng: 77.173954
        },
        "name": "Sumit Verma",
        "address": "Delhi North- B-63/2 Wazipur Industrial Area New Delhi- 110052",
        "mobile": "8826600366",
        "email": "sumit.v@lynk.co.in",
        "stores": "12",
        "centers": "3",
        "brands": "6"
    }
];


export const QUESTION_LIST = [{
    question: "where can I watch?1",
    answer: "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
},
{
    question: "where can I watch?2",
    answer: "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
},
{
    question: "where can I watch?3",
    answer: "Nibh quisque suscipit fermentum netus nulla cras porttitor euismod nulla. Orci, dictumst nec aliquet id ullamcorper venenatis. Fermentum sulla craspor ttitore  ismod nulla."
}]
