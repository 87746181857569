import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import Brands from './Brands/Brands'
import ForBrands from './ForBrands/ForBrands'
import KiranaCredit from './KiranaCredit/KiranaCredit'
import LynkVsOthers from './LynkVsOthers/LynkVsOthers'
import SupplyChain from './SupplyChain/SupplyChain'
import './HomePage.scss'
import { useLocation } from 'react-router-dom'
import OurFootprint from './OurFootprint/OurFootprint'
// import Testimonials from './Testimonials/Testimonials'

const HomePage = () => {

    const location = useLocation();

    useEffect(() => {
        const view = location.state;
        if (view) {
            document.getElementById(view).scrollIntoView({
                behavior: 'smooth', // gives an ease-in-out effect to our scroll
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className='homepage'>
            <Header />
            <ForBrands />
            <SupplyChain />
            <LynkVsOthers />
            <KiranaCredit />
            <Brands />
            {/* <Testimonials /> */}
            <OurFootprint />
            <Footer />
        </div>
    )
}

export default HomePage