import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import PrivacyPage from './components/Footer/PrivacyPage';
import TermsAndConditions from './components/Footer/TermsAndConditions';
import Announcements from './pages/Announcements/Announcements';
import ContactUs from './pages/ContactUs/ContactUs';
import HomePage from './pages/HomePage/HomePage';
import JoinUs from './pages/JoinUs/JoinUs';

const Routes = () => {

    return (
        <>
            <Switch>
                <Route path="/joinus" component={JoinUs} />
                <Route path="/contactus" component={ContactUs} />
                <Route path="/termsandconditions" component={TermsAndConditions} />
                <Route path="/privacy-policy" component={PrivacyPage} />
                <Route path="/announcements" component={Announcements} />
                <Route path="/" component={HomePage} />
                <Redirect key="invalid" to="/" />
            </Switch>
        </>
    );
};

export default Routes;
