import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import GetInTouch from './GetInTouch/GetInTouch'
// import Questions from './Questions/Questions'

const ContactUs = () => {

    useEffect(() => {
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        })

    }, [])

    return (
        <div>
            <Header />
            <GetInTouch />
            {/* <Questions /> */}
            <Footer />
        </div>
    )
}

export default ContactUs