import React from 'react'
import './PrivacyPage.scss'

const PrivacyPage = () => {
    return (
        <div>
            <section className="privacy-policy">
                <div className="container">
                    <div className="mt-10">
                        <div className="text-center col-xs-12">
                            <h2> Privacy Policy </h2>
                            <hr style={{ border: "solid 1px #fbb000", marginTop: 0, marginBottom: 0, width: "15%" }} />
                        </div>
                        <div className="clearfix"></div>
                        <div className="mt-3">
                            <p><strong>1. Scope of the Data Protection and Privacy Policy (“Policy”):</strong></p>
                            <p>
                                1.1. Lynks Logistics Ltd is a Company, incorporated and organized under the laws of India and having its registered office at Auras Corporate Centre, Vth Floor, 98-A Dr. Radhakrishnan Salai, Mylapore, Chennai – 600004. Lynks Logistics Ltd is the sole and exclusive owner of the website registered under the domain name www.lynk.co.in and mobile application or through customer care through a national phone number 1800–258-2424 (hereinafter collectively referred to as “ Lynk Technology Platform or Lynk Platform”). This Policy inter-alia covers the terms concerning the privacy of your Personal Information in our Lynk Technology Platform including but not limited to information related to your past use of the services offered on the Platform, between you and Lynks Logistics Ltd or its affiliates, representatives, successors, permitted assigns. We are highly committed to the privacy of your data and information and in providing excellent service to all of our customers and visitors to the Lynk Technology Platform. By visiting the Lynk Technology Platform, you are accepting to and are in satisfaction of the practices described in this Policy.
                            </p>
                            <p>1.2. Unless restricted by applicable law, you agree that all your personal information collected by Lynks Logistics Ltd through the Lynk Technology Platform may be used and disclosed in a manner as set out in this Policy (see Personal Information Usage).</p>
                            <p>1.3. In reference to this Policy, the term ‘Personal Information' shall mean and include information relating to identified or identifiable natural person such as name, e-mail address, user account log in information and password for such user account, mobile phone number, landline phone number, gender, date of birth, residential address, official address, but excludes any credit card, debit card, cash card or net-banking details, all of which are stored on Payment Processor's secure servers and/or in cookies on your computer.</p>
                            <p>1.4. You will be providing your Personal Information to Lynks Logistics Ltd directly. You agree that your information will be the property of Lynks Logistics Ltd. </p>
                            <p>1.5. You are advised to read this Policy carefully. By accessing the services provided on the Lynk Technology Platform, you agree to the collection and use of your data by us and certain authorized third party in the manner provided in this Policy. If you do not agree with this Policy, please do not use the Lynk Technology Platform.</p>
                            <p><strong>2. Storage of certain information:</strong></p>
                            <p>2.1. We receive and store certain types of information such as IP address of your machine/device from where and when you access our Lynk Technology Platform. Our server captures your activities for various diagnostic and analytical purposes. </p>
                            <p><strong>3. Information collected by us:</strong></p>
                            <p>3.1. Notwithstanding any Personal Information that may be collected by us when you voluntarily provide such Personal Information for the purposes of creation of any user account or for making payment for the purpose of availing our services, we may collect additional information in connection with your participation in any promotions or competitions offered by us and information you provide when giving us feedback. We also monitor customer traffic patterns and use of the Lynk Technology Platform, which enables us to improve the service we provide. We will collect only such information as is necessary and relevant to us to provide you with the services available on the Lynk Technology Platform.</p>

                            <p>3.2. Anonymous Information:  In addition to the information that you explicitly provide during your interactions on the Technology Platfrom, we will automatically receive and collect certain anonymous information in standard usage logs through our web server, including computer-identification information obtained from "cookies" sent to your browser from:</p>
                            <p>a) web server cookie stored on your hard drive/device; </p>
                            <p>b) an IP address, assigned to the computer/device which you use;</p>
                            <p>c) the domain server through which you access our service; </p>
                            <p>d) the type of computer/device you're using; and</p>
                            <p>e) the type of web browser you're using.</p>
                            <p>3.3. You can terminate your account at any time. However, your information may remain stored in archive on our servers even after the deletion or the termination of your account.</p>
                            <p><strong>4. Personal Information Usage:</strong></p>
                            <p>4.1. We use your Personal Information to process your registration, process any orders that you may make for any services displayed on the Lynk Technology Platform, provide you with improved services, contact you when it is needed by telephone, facsimile and e-mail, and to advise you of services which may be of interest to you, inviting you to be a participant or a respondent to an online event that is hosted on the Lynk Technology Platform. Further, the relevant Personal Information collected by us may be used to:</p>
                            <p>(i) provide you with statements of your account; or</p>
                            <p>(ii) to communicate with you on any matter relating to the conduct of your account; or</p>
                            <p>(iii) to communicate the details of any orders / processing of any orders placed by you relating to products displayed on the Lynk Technology Platform.</p>
                            <p>4.2. We may also use Aggregate Information and statistics for the purposes of monitoring website usage in order to help us develop the Lynk Technology Platform, our services and may provide such Aggregate Information to third parties on an aggregate basis. These statistics will not include information that can be used to identify any individual customer. We may organize contests and surveys and the information collected during these events may be used by us to improve your overall customer experience. Such information will only be shared with third parties on an aggregate basis.</p>

                            <p>
                                For the purposes of this Policy, <strong>"Aggregate Information"</strong> shall mean and include information that is recorded about users and collected into groups so that it no longer reflects or references an individually identifiable user. Such information does not identify you individually.
                            </p>
                            <p>4.3. Personal Information collected by the Lynk Technology Platform may be transferred to other sites of ours where it is necessary to meet the purpose for which you have submitted the information. By submitting data on this Lynk Technology Platform, you are providing explicit consent to transmission of data collected on the same.</p>
                            <p><strong>5. Information Sharing and Disclosures:</strong></p>
                            <p>5.1. We do not rent, sell or share your Personal Information to third parties –</p>
                            <p>a) Unless we have permission from you;</p>
                            <p>b) Unless we have to provide services that are requested by you;</p>
                            <p>c) Unless we have to help investigate, prevent or take action regarding unlawful and illegal activities, suspected fraud, potential threat to the safety or security of any person or organization or property or asset or rights of the Lynk Technology Platform from unauthorized use or misuse of the same, violations of our terms and conditions or to defend against legal claims/proceedings;</p>
                            <p>
                                d) Unless upon occurrence of special circumstances detailed hereunder -
                            </p><ul className="privacyli">
                                <li>to respond to subpoenas, court orders, judicial proceedings, or other legal processes;</li>
                                <li>to enforce the terms of the Terms and Conditions or the terms of this Policy;</li>
                                <li>to respond to claims that any photo, text, or other material that violates the rights of third parties; or</li>
                                <li>to protect the rights, property, or personal safety of the Lynk Technology Platform, its users, or the general public.</li>

                            </ul>
                            <p></p>
                            <p>
                                5.2. We provide certain required personal and contact information to affiliated companies or other trusted business partners for the purpose of providing the required service on our behalf. We require that these parties agree to process such information based on our instructions and in compliance with this Policy and any other appropriate confidentiality and security measures.
                            </p>
                            <p>5.3. Business Transfer: As we continue to develop our business, we might be acquired completely by a company/LLP or merge with any other company. In such transactions, customer information generally is one of the business assets. In such a case, we will ensure the protection of your Personal Information as per this Policy. You hereby specifically consent and grant permission to the disclosure, and transferring, of information to such third parties.</p>
                            <p>5.4. We release account and other Personal Information when we believe release is appropriate to comply with applicable law; to enforce or apply our Terms and Conditions (see Opt Out T&amp;C Policy) and other relevant agreements; or protect, or safety of company, our users, others. This includes exchanging information with other companies and organization for fraud protection and other similar matters. You hereby specifically consent and grant permission to disclosure and transferring of information to such third parties, as may be required.</p>
                            <p>5.5. We may share with third parties certain pieces of Aggregate Information.</p>

                            <p><strong>6. Information Security:</strong></p>
                            <p>6.1. We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, as well as physical security measures to guard against unauthorized access to systems where we store personal data. We restrict access to Personal Information to our employees, contractors and agents who need to know that information in order to operate, develop or improve our services. These individuals are bound by confidentiality obligations and may be subject to discipline, including termination and criminal prosecution, if they fail to meet these obligations.</p>
                            <p>6.2. Although we endeavor to safeguard the confidentiality of your personally identifiable information, transmissions made by means of the internet cannot be made absolutely secure. By using this Lynk Technology Platform, you agree that we will have no liability for disclosure of your information due to errors in transmission or unauthorized acts of third parties.</p>

                            <p>6.3. You further agree that you are solely responsible and liable for, and shall indemnify us against any and all costs, expenses, damages, fees, etc. that we may incur or suffer due to any Personal Information or other materials that you post, upload, submit, and otherwise make available on the Lynk Technology Platform, including areas of the Platform that are available to the public. We have no control over and cannot protect Personal Information that you disclose in public areas of the Lynk Technology Platform. If you disclose your Personal Information in public areas, it may be collected and used by third parties, without our or your knowledge. You agree that by displaying your Personal Information or photographs on the Lynk Technology Platform and the internet, for the intention of showing the information / those photographs to your friends, family, acquaintances, clients, business partners, and others, that you you are relinquishing certain traditional privacy rights, wherein anyone with access to the internet has the potential ability to view your information / photographs. If you do not wish to relinquish these traditional privacy rights, do not share your information / photographs. The information provided by you is stored in secure computers. Advanced security measures are used to prohibit access to our computers by unauthorized persons. </p>
                            <p><strong>7. Policy Compliance:</strong></p>
                            <p>7.1. We regularly review our compliance with this Policy. Please feel free to direct any questions or concerns regarding this Policy or our treatment of Personal Information by contacting us through the customer care number 1800-102-7722, provided on the Lynk Technology Platform or by e-mailing to us at contact@lynk.co.in. When we receive complaints at this address, it is our policy to contact the complaining user regarding his or her concerns. We will cooperate with the appropriate regulatory authorities to resolve any complaints regarding the transfer of personal data that cannot be resolved between us and an individual.</p>
                            <p><strong>8. Disputes:</strong></p>
                            <p>8.1. In the event of any dispute, controversy or claim directly or indirectly caused by, arising out of or relating to this Policy will be governed by the laws of India. </p>

                            <p>8.2. The parties shall use all reasonable endeavors to first resolve the matter on an amicable basis, within a period of Sixty (60) days. No recourse to arbitration by one party against the other party under this Policy shall take place unless and until such procedure has been followed.</p>
                            <p>8.3. Any unresolved disputes or claims which may arise out of or in connection with the Policy shall be referred to arbitration in accordance with the provisions of the Indian Arbitration and Conciliation Act, 1996. The arbitration proceedings will be presided by a sole arbitrator to be appointed based on mutual consent of both parties in writing. </p>
                            <p>8.4. The arbitration proceedings shall be in English and shall be held in Chennai, India.</p>

                            <p>8.5. The award of the arbitrator shall be final and binding on both the parties. </p>
                            <p>8.6. Subject to the above, you hereby submit yourself to the exclusive jurisdiction of the Courts in Chennai, India.</p>
                            <p><strong>9. Conditions of Use, Notices, and Revisions</strong></p>
                            <p>9.1. By using our services, you consent to the collection, use, and disclosure of information in accordance with the terms of this Privacy Policy. We reserve the right to modify or amend this Privacy Policy at any time. If we make material changes to this Privacy Policy, we will notify you here, by e-mail, or by means of a notice on our Lynk Technology Platform so that you are always aware of the information that we collect, how we use it, and under what circumstances we disclose it. Unless we clearly state otherwise, the changes to our Privacy Policy will affect only the information we collect after the effective date of the change. If you have any questions or concerns regarding any of the terms of this Privacy Policy, please feel free to contact us at contact@lynk.co.in.</p>

                            <p><strong>10. Your Choices: </strong></p>
                            <p>10.1. By submitting your Personal Information, you consent to the use of that information as set out in this Policy. We welcome your views on the Lynk Technology Platform and the Policy. However, submitting personally identifiable information is entirely voluntary. You are not required to register with us in order to browse access our Lynk Technology Platform. Please note that we offer some services only to visitors who do register. At any point in time, you can correct and make changes to your Personal Information by accessing your information in My Account section of the Website/Mobile Application. You may change your interests at any time and may opt-in or opt-out of any marketing / promotional / newsletters mailings. We reserve the right to send you certain service related communication, considered to be a part of your account with us, without offering you the facility to opt-out. You may update your information and change your account settings at any time. Please use the contact information provided below for the above purposes.</p>
                            <p>10.2. If we plan to use your personally identifiable information for any commercial purposes, we will notify you at the time we collect that information and allow you to opt-out of having your information used for those purposes.</p>
                        </div>
                    </div>
                </div>

            </section>

        </div>
    )
}

export default PrivacyPage