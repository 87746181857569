import React, { useEffect } from 'react'
import './Announcements.scss'
import lynk from '../../assets/images/Logo/Lynk.png'
import swiggy from '../../assets/images/Logo/Swiggy.png'
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'


const Announcements = () => {

    useEffect(() => {
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        })

    }, [])

    return (
        <div className='announcements'>
            <Header></Header>
            <div className="announcements-container">
                <div className='announcements-logo-wrap'>
                    <div>
                        <img src={lynk} alt="" width="143px" />
                    </div>
                    <div>
                        <img src={swiggy} alt="" width="92px" />
                    </div>
                </div>
                <div><h2>PRESS RELEASE</h2></div>
                <div className='announcements-header'>
                    <p><h2>Swiggy announces acquisition of retail distribution company LYNK</h2></p>
                    <p><em>Deal paves the way for Swiggy's entry into India's massive retail market
                        with a technology-led distribution platform
                        LYNK will continue to operate as an independent business post the acquisition</em></p>
                </div>
                <p><strong>Bangalore, July 13, 2023:</strong> Swiggy, India's leading on-demand convenience platform, announced
                    today that it has entered into a definitive agreement to acquire <a href="https://www.lynk.co.in/">LYNK Logistics Limited</a>. LYNK will
                    continue to operate as an independent business post the acquisition led by Co-founder and
                    CEO, Shekhar Bhende.</p>
                <p>Founded in 2015 by Abinav Raja and Shekhar Bhende, LYNK is one of India's largest tech-driven
                    FMCG retail distribution companies. LYNK enables leading FMCG brands to grow their retail
                    presence through its network of 100,000+ retail stores across the top 8 cities of India. The
                    company has demonstrated strong performance, growing 2.5x year-on-year with improved
                    profitability. LYNK leverages a proprietary, integrated technology platform to power the entire
                    retail distribution value chain across warehousing, inventory management and logistics
                    operations.</p>
                <p>With this acquisition, Swiggy enters India's food and grocery retail market, which is amongst the
                    world's largest and fastest growing, estimated to be {'>'} 570B$ in size and expected to grow at 8%
                    year-on-year. LYNK works with leading FMCG brands as an authorized distributor, connecting
                    them to retail stores and offering a one stop solution to achieve their sales and growth goals.
                    Further, leveraging its proprietary technology stack, LYNK offers faster order to delivery
                    turnaround and improved on-the-shelf availability through better fill rates to retail stores
                    enabling them to increase sales and serve their customers better. Post acquisition, LYNK will
                    leverage Swiggy's strength in technology and logistics to rapidly scale their existing platform</p>
                <p><em>“I am excited to have the LYNK team join Swiggy. LYNK is uniquely positioned in the retail
                    distribution space with their brand-first, tech-led operating model and has demonstrated
                    success with multiple FMCG brands. Our experience in supply chain and logistics gives Swiggy
                    the unique opportunity to help LYNK scale up their offerings and empower retailers to serve their
                    customers better, ”</em> <strong>said Sriharsha Majety, CEO, Swiggy.</strong>
                </p>
                <p><em>“Over the last few years, we have focused squarely on helping FMCG brands to meet their retail
                    ambitions. Given our rapid growth, we believe we are uniquely placed to lead the digitization of
                    retail distribution in India. With Swiggy, we now hope to further accelerate our growth and
                    double down on the tremendous opportunity before us. We are deeply encouraged by our
                    interactions with Swiggy; both companies have an innate builder bias and I look forward to
                    working together,”</em> <strong>said Shekhar Bhende, Co-founder, LYNK.</strong></p>
                <p>Avendus acted as the sole financial advisor to LYNK and its shareholders on this transaction.</p>
                <p><strong>About LYNK Logistics Limited</strong></p>
                <p>LYNK is building a full stack FMCG retail distribution platform. WIth its robust technology stack,
                    LYNK is sharpening how brands go-to-market, building a distribution platform of the future.
                    LYNK works with several iconic consumer brands in India across major cities, with a rapidly
                    growing network of retail stores. Learn more <a href="https://www.lynk.co.in/">https://www.lynk.co.in/</a></p>
                <p><strong>About Swiggy</strong></p>
                <p>Founded in 2014, Swiggy is India's leading on-demand convenience platform with a vision to
                    elevate the quality of life for the urban consumer by offering unparalleled convenience. It
                    connects consumers to over 250,000 restaurant partners in hundreds of cities. Its quick
                    commerce grocery service Instamart is present in over 25 cities. Swiggy Dineout offers users
                    experiences in high-use categories like dining out and events in close to 25 cities across the
                    country. Using innovative technology, Swiggy provides a hassle-free, fast, and reliable delivery
                    experience. Every order delivered by Swiggy's delivery executives, ensures a host of
                    customer-centric features like lightning-fast delivery, no minimum order value, live order
                    tracking, and 24/7 customer support. For more information, visit <a href="https://www.swiggy.com/">www.swiggy.com</a></p>
                <p><em><strong>For further information, please contact –</strong></em></p>
                <p>Michelle Kumar- <a href="mailto:michelle.kumar@swiggy.in">michelle.kumar@swiggy.in</a></p>
                <p>Sanjana Shetty- <a href="mailto:sanjana.shetty1@swiggy.in">sanjana.shetty1@swiggy.in</a></p>
            </div>
            <Footer />
        </div>
    )
}

export default Announcements
