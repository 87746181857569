import ReactGA from 'react-ga4';

const addAnalytics = (category, message, data) => {
    ReactGA.event({
        category: category,
        action: message,
        label: JSON.stringify(data)
    });
}

export default addAnalytics;