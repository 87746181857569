import React from 'react'
import underline from '../../../assets/images/underline.svg'
import sales from '../../../assets/images/sales.svg'
import truck from '../../../assets/images/truck.svg'
import career_opp from '../../../assets/images/career_opp.svg'
import employment_verification from '../../../assets/images/employment_verification.svg'
import support from '../../../assets/images/support.svg'
import contact_us_pic from '../../../assets/images/contact_us_pic.png'
import './GetInTouch.scss'
import CitySelect from '../CitySelect/CitySelect'

const GetInTouch = () => {
    return (
        <div className='get-in-touch'>
            <div className='get-in-touch-text heading-text short'>
                <p>Get In <span>Touch<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='get-in-touch-text-underline' width="100%" /></span></p>
            </div>
            <CitySelect />
            <div className='email-wrap'>
                <div className='email'>
                    <div>
                        <img src={sales} alt="" height="54px" />
                        <div>To book with us</div>
                        <div><a href="mailto:sales@lynk.co.in">sales@lynk.co.in</a></div>
                    </div>
                    <div>
                        <img src={truck} alt="" height="54px" />
                        <div>To attach trucks</div>
                        <div><a href="mailto:supply@lynk.co.in">supply@lynk.co.in</a></div>
                    </div>
                    <div>
                        <img src={career_opp} alt="" height="54px" />
                        <div>For career oppurtunities</div>
                        <div><a href="mailto:hr@lynk.co.in">hr@lynk.co.in</a></div>
                    </div>
                    <div>
                        <img src={employment_verification} alt="" height="54px" />
                        <div>For employment verfication</div>
                        <div><a href="mailto:onboarding@lynk.co.in">onboarding@lynk.co.in</a></div>
                    </div>
                    <div>
                        <img src={support} alt="" height="54px" />
                        <div>For other queries and feedback</div>
                        <div><a href="mailto:support@lynk.co.in">support@lynk.co.in</a></div>
                    </div>
                </div>
                <div>
                    <img src={contact_us_pic} className="contact-us-pic" alt="" />
                </div>
            </div>
        </div>
    )
}

export default GetInTouch
