import React, { useEffect, useState } from 'react'
import './Header.scss'
import lynk_logo from '../../assets/images/lynk_logo.png'
import menu from '../../assets/images/menu.svg'
import close_icon from '../../assets/images/close_icon.svg'
// import playstore from '../../assets/images/Google_Play_Store.svg'
// import appstore from '../../assets/images/app-store.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { NAV_LINKS } from '../../config/data'
import addAnalytics from '../../utils/analytics'

const NavLink = ({ children, scrollToId, onClick, url }) => {
    const history = useHistory();

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        addAnalytics("Header", `User Redirects to ${url === "/" ? "" : url}${scrollToId || ""}`)
        if (url) {
            history.push(url, scrollToId);
        }
        if (scrollToId) {
            document.getElementById(scrollToId).scrollIntoView({
                behavior: 'smooth', // gives an ease-in-out effect to our scroll
            });
        }
    };

    return (
        <div
            className='navlink'
            onClick={handleClick}
        >
            {children}
        </div>
    );
};

const Header = () => {

    const [isHamburgerOpen, setHamburgerOpen] = useState(false);

    useEffect(() => {
        if (isHamburgerOpen) document.body.style.overflow = 'hidden';
        else {
            document.body.style.overflowY = 'auto';
        }
    }, [isHamburgerOpen]);

    return (
        <div className="header shadow">
            <div>
                <NavLink
                    scrollToId="brands"
                    url="/"
                >
                    <img src={lynk_logo} alt="lynk logo" width="136px" />
                </NavLink>
            </div>
            <div className='navlink-wrap'>
                {NAV_LINKS.map(
                    ({ navLinkId, scrollToId, url }, i) =>
                        <NavLink
                            key={i}
                            scrollToId={scrollToId}
                            url={url}
                        >
                            {navLinkId}
                        </NavLink>
                )}
            </div>
            <div className='menu' onClick={() => setHamburgerOpen(true)}>
                <img src={menu} alt="menu" />
            </div>
            <Hamburger
                isHamburgerOpen={isHamburgerOpen}
                setHamburgerOpen={setHamburgerOpen}
            />
        </div>
    )
}

const Hamburger = (props) => {

    const { isHamburgerOpen, setHamburgerOpen } = props;

    return (
        <div className={`hamburger ${isHamburgerOpen ? "open" : ""}`}>
            <div className='logo-x'>
                <NavLink
                    scrollToId="brands"
                    url="/"
                >
                    <div className='hamburger-logo'>
                        <img src={lynk_logo} alt="lynk logo" width="90px" />
                    </div>
                </NavLink>

                <div onClick={() => setHamburgerOpen(false)}>
                    <img src={close_icon} alt="close" width="14px" />
                </div>
            </div>
            <div className='ham-navlink'>
                {NAV_LINKS.map(
                    ({ navLinkId, scrollToId, url }) =>
                        <NavLink
                            scrollToId={scrollToId}
                            key={navLinkId}
                            url={url}
                            onClick={() => setHamburgerOpen(false)}
                        >
                            {navLinkId}
                        </NavLink>
                )}
            </div>

            {/* <div className='apps'>
                <a href="https://play.google.com/store/apps/details?id=com.lynk.customer&hl=en_IN&gl=US">
                    <img src={playstore} alt="playstore" width="130px" />
                </a>
                <a href="https://apps.apple.com/in/app/lynk/id1222242493">
                    <img src={appstore} alt="playstore" width="130px" />
                </a>
            </div> */}
        </div>
    );
}

export default Header

