import React from 'react'
import underline from '../../../assets/images/underline.svg'
import fun_at_lynk_web from '../../../assets/images/OfficeImageWeb.png'
import fun_at_lynk_mobile from '../../../assets/images/OfficeImageMobile.png'
import './FunAtLynk.scss'

const FunAtLynk = () => {

    // const imageList = require.context('../../../assets/images/OfficeImages', true);

    // const loadImage = (image_name) => {
    //     try {
    //         return imageList(image_name);
    //     }
    //     catch (err) {
    //         console.log(err);
    //     }
    // }
    return (
        <div className='fun-at-lynk'>
            <div className='fun-at-lynk-text heading-text short'>
                <p>Fun At <span>Lynk<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='fun-at-lynk-text-underline' width="100%" /></span></p>
                <div className='subtext'>We at Lynk, believe that the people who work with us are our greatest strength.
                    Our magic wand for motivation is to appreciate each other when we succeed
                    and laugh at our mistakes.</div>
            </div>
            {/* <div className='image-slider'>
                {imageList.keys().map(i =>
                    <img src={loadImage(i)} alt={i} key={i} height="350px" />
                )}
            </div> */}
            <img className='fun-at-lynk-image-web' src={fun_at_lynk_web} alt="" width="100%" />
            <img className='fun-at-lynk-image-mobile' src={fun_at_lynk_mobile} alt="" width="100%" />
        </div>
    )
}

export default FunAtLynk