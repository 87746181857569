import React from 'react'
import underline from '../../../assets/images/underline.svg'
import ForBrandsLogo from '../../../assets/images/ForBrandsLogo.png'
import same_store_sale from '../../../assets/images/same_store_sale.png'
import new_store_sales from '../../../assets/images/new_store_sales.svg'
import retailer_happiness from '../../../assets/images/retailer_happiness.svg'
import './ForBrands.scss'

const ForBrands = () => {
    return (
        <div id="brands">
            <div className='for-brands-wrap'>
                <div className='for-brands-text heading-text'>
                    <p>A Kirana Commerce <br /> platform of the future, <br />for <span>Brand Owners<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='for-brands-text-underline' width="100%" /></span></p>
                </div>
                <div className='for-brands-logo'>
                    <img src={ForBrandsLogo} alt="" width="700px" />
                </div>
            </div>
            <div className='for-brands-page'>
                <div className='for-brands-text heading-text short'>
                    <p>For <span>Brands<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='for-brands-text-underline' width="100%" /></span></p>
                    <div className='subtext'>The only platform you need, to reach General Trade</div>
                </div>
                <div className='for-brands-flex-row image-heading-subheading-list'>
                    <div>
                        <img src={same_store_sale} alt="" height="50px" />
                        <div>Grow New Store Sales</div>
                        <div>Increase relevant new store coverage by more than 30% using Lynk's proprietary geocoded store universe</div>
                    </div>
                    <div>
                        <img src={new_store_sales} alt="" height="50px" />
                        <div>Grow Same Store Sales</div>
                        <div>Drive an intelligent assortment in each store by leveraging hyperlocal consumption patterns</div>
                    </div>
                    <div>
                        <img src={retailer_happiness} alt="" height="50px" />
                        <div>Drive Retailer Happiness</div>
                        <div>Consistently high retailer OTIF across the country, using sealed crates and automated communication</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForBrands