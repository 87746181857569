//polyfills
import 'core-js';
import 'raf/polyfill';
import React from 'react';
import ReactGA from "react-ga4";
import { createRoot } from 'react-dom/client';
// import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
// import store from './redux/configStore';
import Routes from './Routes';
import './index.scss';

export const history = createBrowserHistory();

const root = createRoot(document.getElementById("root") || document.createElement('div')); // for testing only;


ReactGA.initialize("G-W0CW97N231");
ReactGA.send("pageview");

root.render(
    // <Provider store={store}>
    <Router history={history}>
        <Routes />
    </Router>
    // </Provider>
);
