import React from 'react'
import './SupplyChain.scss'
import supply_chain from '../../../assets/images/supply_chain.png'
import underline from '../../../assets/images/underline.svg'
import geofenced from '../../../assets/images/geofenced.svg'
import just_in_time from '../../../assets/images/just_in_time.svg'
import state_of_art from '../../../assets/images/state_of_art.svg'

const SupplyChain = () => {
  return (
    <div id="supplyChain">
      <div className='supply-chain'>
        <div>
          <img src={supply_chain} alt="" width="600px" />
        </div>
        <div>
          <div className='supply-chain-text heading-text short'>
            <p>We're building a Kirana <br /><span>Supply Chain of the future<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='supply-chain-text-underline' width="100%" /></span></p>
            <div className='subtext'>A first of its kind supply chain to cater to unique Kirana B2B requirements</div>
          </div>
          <div className='supply-chain-flex-col image-heading-subheading-list'>
            <div>
              <img src={geofenced} alt="" height="50px" />
              <div>100% geofenced delivery, Next Day!</div>
              <div>Increases retailer loyalty </div>
            </div>
            <div>
              <img src={just_in_time} alt="" height="50px" />
              <div>Sophisticated Order Processing Centers</div>
              <div>Accurately convert cases to retailer orders, at scale</div>
            </div>
            <div>
              <img src={state_of_art} alt="" height="50px" />
              <div>Innovative Just-in-Time models</div>
              <div>Reduces inventory and cost to serve</div>
            </div>
          </div>
        </div>
      </div>
      <div className='supply-chain-mobile'>
        <div className='supply-chain-text heading-text short'>
          <p>We're building a Kirana <br /><span>Supply Chain of the future<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='supply-chain-text-underline' width="100%" /></span></p>
          <div className='subtext'>A first of its kind supply chain to cater to unique Kirana B2B requirements</div>
        </div>
        <div>
          <img src={supply_chain} alt="" width="600px" />
        </div>
        <div className='supply-chain-flex-col image-heading-subheading-list'>
          <div>
            <img src={geofenced} alt="" height="50px" />
            <div>100% geofenced delivery, Next Day!</div>
            <div>Increases retailer loyalty </div>
          </div>
          <div>
            <img src={just_in_time} alt="" height="50px" />
            <div>Sophisticated Order Processing Centers</div>
            <div>Accurately convert cases to retailer orders, at scale</div>
          </div>
          <div>
            <img src={state_of_art} alt="" height="50px" />
            <div>Innovative Just-in-Time models</div>
            <div>Reduces inventory and cost to serve</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SupplyChain