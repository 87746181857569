import React from 'react'
import underline from '../../../assets/images/underline.svg'
import kirana_credit from '../../../assets/images/kirana_credit.png'
import './KiranaCredit.scss'

const KiranaCredit = () => {
    return (
        <div id="kiranaCredit">
            <div className='kirana-credit-wrap'>
                <div className='kirana-credit-logo'>
                    <img src={kirana_credit} alt="" width="460px" />
                </div>
                <div className='kirana-credit-text heading-text short'>
                    <p>Formalizing Credit for Retailers - <br /><span>Buy Now, Pay Later<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='kirana-credit-text-underline' width="100%" /></span></p>
                    <div className='subtext'>Data driven, wide credit coverage for retailers, empowering
                        the Indian Kirana and optimizing Brand sales</div>
                </div>
            </div>

            <div className='kirana-credit-wrap-mobile' id="kiranaCredit">
                <div className='kirana-credit-text heading-text short'>
                    <p>Formalizing Credit for Retailers - <span>Buy Now, Pay Later<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='kirana-credit-text-underline' width="100%" /></span></p>
                </div>
                <div className='kirana-credit-logo'>
                    <img src={kirana_credit} alt="" width="100%" />
                </div>
                <div className='subtext'>Data driven, wide credit coverage for retailers, empowering
                    the Indian Kirana and optimizing Brand sales</div>
            </div>
        </div>
    )
}

export default KiranaCredit