import React from 'react';
import { ComposableMap, Geographies, Geography, Marker } from 'react-simple-maps';
import { CITY_LIST } from '../../../config/data';
import addAnalytics from '../../../utils/analytics';
import INDIA_TOPO_JSON from './india.topo.json'

const PROJECTION_CONFIG = {
  scale: 1100,
  center: [78.9629, 22.5937] // always in [East Latitude, North Longitude]
};

const DEFAULT_COLOR = '#F8E8AE';
const CURRENT_COLOR = '#F9D762';
const BORDER_COLOR = '#FFF';

const geographyStyle = {
  default: {
    outline: 'none'
  },
  hover: {
    fill: '#FACD38',
    transition: 'all 250ms',
    outline: 'none'
  },
  pressed: {
    outline: 'none'
  }
};

const Map = ({ setCity, selectedCity }) => {

  const handleSelectCity = (city) => {
    setCity(city);
    addAnalytics("Our Footprint", `User hovers on ${city.city}`);
  }

  return (
    <div>
      <ComposableMap
        projectionConfig={PROJECTION_CONFIG}
        projection="geoMercator"
      >
        <Geographies geography={INDIA_TOPO_JSON}>
          {({ geographies }) =>
            geographies.map(geo => {
              const current = CITY_LIST.find(s => s.id === geo.id);
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  fill={current ? CURRENT_COLOR : DEFAULT_COLOR}
                  stroke={BORDER_COLOR}
                  strokeWidth="1px"
                  style={geographyStyle}
                />
              );
            })
          }
        </Geographies>
        {CITY_LIST.map(city =>
          <Marker coordinates={city.coordinates} onMouseEnter={() => handleSelectCity(city)} key={city.city} className="marker">
            <g width="29" height="39" transform="translate(-14.5, -39)" viewBox="0 0 29 39" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M14.4366 0.269532C10.6078 0.269532 6.9357 1.78463 4.22832 4.48151C1.52094 7.1784 0 10.8362 0 14.6501C0 21.1505 9.66901 33.4097 13.1811 37.6496C13.3283 37.8428 13.5184 37.9993 13.7364 38.1071C13.9544 38.2149 14.1945 38.271 14.4379 38.271C14.6814 38.271 14.9215 38.2149 15.1395 38.1071C15.3575 37.9993 15.5476 37.8428 15.6948 37.6496C19.2069 33.4097 28.8759 21.1505 28.8759 14.6501C28.8759 12.7614 28.5024 10.8912 27.7767 9.14627C27.051 7.40136 25.9874 5.81593 24.6465 4.48053C23.3057 3.14513 21.7139 2.08592 19.9621 1.36339C18.2102 0.640861 16.3327 0.269166 14.4366 0.269532ZM14.4366 20.0919C13.2791 20.0919 12.1475 19.75 11.185 19.1094C10.2226 18.4688 9.47246 17.5583 9.02949 16.493C8.58652 15.4278 8.47066 14.2556 8.69649 13.1247C8.92231 11.9938 9.47969 10.955 10.2982 10.1397C11.1167 9.32438 12.1595 8.76914 13.2948 8.54419C14.4301 8.31925 15.6068 8.4347 16.6762 8.87594C17.7457 9.31719 18.6597 10.0644 19.3028 11.0231C19.9459 11.9819 20.2891 13.109 20.2891 14.262C20.2891 15.8082 19.6725 17.2911 18.5749 18.3844C17.4773 19.4777 15.9888 20.0919 14.4366 20.0919Z" fill={selectedCity.city === city.city ? "#007AAA" : "#00AAAA"} />
            </g>
          </Marker>
        )}
      </ComposableMap>
    </div>
  );
}

export default Map;
