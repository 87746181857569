import React from 'react'
import underline from '../../../assets/images/underline.svg'
import resume from '../../../assets/images/resume.svg'
import './WeAreHiring.scss'
// import addAnalytics from '../../../utils/analytics';


const WeAreHiring = () => {

    // const handleOnClick = () => {
    //     addAnalytics("Join Us", "User Clicks on Join Us")
    //     window.location.href = 'https://lynk.freshteam.com/jobs'
    //     // window.open('https://lynk.freshteam.com/jobs', '_blank', 'noopener noreferrer');
    // }

    return (
        <div className='we-are-hiring'>
            <div className='we-are-hiring-text heading-text short'>
                <p>We're <span>Hiring!<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='we-are-hiring-text-underline' width="100%" /></span></p>
                <div className='subtext'>We're always on the lookout for talent.<br /> Come say hello and we'll figure out if you're a good fit!</div>
            </div>
            {/* <button className='apply-now-cta' onClick={handleOnClick}>Join Us</button> */}
            <a href="mailto:hr@lynk.co.in">
                <div className='send-resume'>
                    <div className='send-resume-text heading-text short'>
                        <p>Plese send your resume <br />to  <span>hr@lynk.co.in</span></p>
                    </div>
                    <img src={resume} alt="" width="50%" />
                </div>
            </a>
        </div>
    )
}

export default WeAreHiring
