import React, { useEffect } from 'react'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import FunAtLynk from './FunAtLynk/FunAtLynk'
import WeAreHiring from './WeAreHiring/WeAreHiring'

const JoinUs = () => {

    useEffect(() => {
        document.body.scrollTo({
            top: 0,
            behavior: "smooth"
        })

    }, []);


    return (
        <div>
            <Header />
            <WeAreHiring />
            <FunAtLynk />
            <Footer />
        </div>
    )
}

export default JoinUs