import React, { useRef, useState } from 'react'
import L from 'leaflet';
import { CITY_LIST } from '../../../config/data';
import { OSM_URL } from '../../../config/urls';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import gps_icon from '../../../assets/images/gps_icon.svg'
import './CitySelect.scss'
import addAnalytics from '../../../utils/analytics';

const CitySelect = () => {

    const mapRef = useRef([])
    const [selectedCity, setSelectedCity] = useState(CITY_LIST[0]);

    const images = require.context('../../../assets/images/City', true);
    const loadImage = () => {
        try {
            return images(`./${selectedCity.city}.jpg`);
        }
        catch (err) {
            console.log(err);
        }
    }

    const handleCitySelect = (city) => {
        addAnalytics("Contact", `User Clicks on ${city.city}`)
        setSelectedCity(city);
    }

    return (
        <div>
            <RadioButton selectedCity={selectedCity} setSelectedCity={handleCitySelect} />
            <div className='map-view-wrap'>
                <MapView position={selectedCity.latlng || { lat: 0, lng: 0 }} mapRef={mapRef} />
                <div className='city-image-address'>
                    <img src={loadImage()} alt="" width="100%" />
                    <div>
                        <div>Address</div>
                        <div>{selectedCity.address}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const RadioButton = ({ selectedCity, setSelectedCity }) => {

    return (
        <div className='city-button-wrap'>
            {CITY_LIST.map(city =>
                <label htmlFor={city.city} className="city-button" key={city.city}>
                    <input
                        type="radio"
                        id={city.city}
                        name="tab"
                        value={JSON.stringify(city)}
                        checked={selectedCity.city === city.city}
                        onChange={(e) => setSelectedCity(JSON.parse(e.target.value))}
                    />
                    <div>{city.city}</div>
                </label>
            )}
        </div>
    )
}


const makeMarkerIcon = () => {

    return new L.divIcon({
        iconSize: [36, 30],
        iconAnchor: [18, 9],
        html: `<img src="${gps_icon}" alt="marker" width="30px"/>`
    });
};

const MapView = ({ position, mapRef }) => {

    const zoom = 12;

    return (
        <div className='map-view'>
            <MapContainer
                ref={mapRef}
                center={position}
                zoom={zoom}
                minZoom={12}
                preferCanvas={true}
                zoomControl={false}
                keyboard={false}
                doubleClickZoom={false}
            >
                <ChangeView center={position} zoom={zoom} />
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright" target="_blank" rel="noopener noreferrer">OSM</a>'
                    url={`${OSM_URL}/tile/{z}/{x}/{y}.png`}
                />
                <Marker
                    position={position}
                    icon={makeMarkerIcon()}
                    keyboard={false}
                />
            </MapContainer>
        </div>);
}

const ChangeView = ({ center, zoom }) => {
    const map = useMap();
    map.setView(center, zoom);
    return null;
}

export default CitySelect