import React from 'react'
import lynk_logo from '../../assets/images/lynk_logo.png'
import twitter from '../../assets/images/Twitter.svg'
import facebook from '../../assets/images/Facebook.svg'
import instagram from '../../assets/images/instagram.svg'
// import playstore from '../../assets/images/Google_Play_Store.svg'
// import appstore from '../../assets/images/app-store.svg'
import './Footer.scss'
import { useHistory } from 'react-router-dom'
import { NAV_LINKS } from '../../config/data'
import addAnalytics from '../../utils/analytics'

const NavLink = ({ children, scrollToId, url }) => {

    const history = useHistory();

    const handleClick = () => {
        addAnalytics("Footer", `User Redirects to ${url === "/" ? "" : url}${scrollToId || ""}`)
        if (url) {
            history.push(url, scrollToId);
        }
        if (scrollToId) {
            document.getElementById(scrollToId).scrollIntoView({
                behavior: 'smooth', // gives an ease-in-out effect to our scroll
            });
        }
    };

    return (
        <div
            onClick={handleClick}
            id={scrollToId}
            className='navlink'
        >
            {children}
        </div>
    );
};

const Footer = () => {

    const handleOnClickLink = (action) => {
        addAnalytics("Footer", `User Clicks on ${action}`)
    }

    return (
        <div className='footer'>
            <div className='logo'>
                <NavLink
                    scrollToId="brands"
                    url="/"
                >
                    <img src={lynk_logo} alt="lynk logo" width="136px" />
                </NavLink>
                <div className='social-media'>
                    <a href="https://twitter.com/lynklogistics" onClick={() => handleOnClickLink("Twitter")}>
                        <img src={twitter} alt="twitter" width="24px" />
                    </a>
                    <a href="https://www.instagram.com/lynk_logistics" onClick={() => handleOnClickLink("Instagram")}>
                        <img src={instagram} alt="instagram" width="24px" />
                    </a>
                    <a href="https://www.facebook.com/lynk.india" onClick={() => handleOnClickLink("Facebook")}>
                        <img src={facebook} alt="facebook" width="24px" />
                    </a>
                </div>
            </div>
            <div className='menu-wrap'>
                <div className='menu'>
                    {NAV_LINKS.map(
                        ({ navLinkId, scrollToId, url }) =>
                            <NavLink
                                scrollToId={scrollToId}
                                key={navLinkId}
                                url={url}
                            >
                                {navLinkId}
                            </NavLink>
                    )}
                    <NavLink
                        key={'Announcements'}
                        url={"/announcements"}
                    >
                        {"Announcements"}
                    </NavLink>
                </div>
                {/* <div className='apps'>
                    <a href="https://play.google.com/store/apps/details?id=com.lynk.customer&hl=en_IN&gl=US" onClick={() => handleOnClickLink("GooglePlay")}>
                        <img src={playstore} alt="playstore" width="180px" />
                    </a>
                    <a href="https://apps.apple.com/in/app/lynk/id1222242493" onClick={() => handleOnClickLink("AppStore")}>
                        <img src={appstore} alt="playstore" width="180px" />
                    </a>
                </div> */}
            </div>
            <div className='tnc-wrap'>
                <div className='tnc'>
                    <div><a href="/termsandconditions" target="_blank" onClick={() => handleOnClickLink("Terms and conditions")}>Terms and conditions</a> | <a href="/privacy-policy" target="_blank" onClick={() => handleOnClickLink("Privacy Policy")}>Privacy Policy</a></div>
                    <div>©2021 - LYNK  |   All right reserved</div>
                </div>
                <div className='contact'>
                    <div><a href="mailto:support@lynk.co.in" onClick={() => handleOnClickLink("support@lynk.co.in")}>support@lynk.co.in</a></div>
                    <div>Toll Free- <a href="tel:18002582424" onClick={() => handleOnClickLink("1800 258 2424")}>1800 258 2424</a></div>
                </div>
            </div>
        </div>
    )
}

export default Footer
