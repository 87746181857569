import React, { useState } from 'react'
import { CITY_LIST } from '../../../config/data'
import Contact from './Contact'
import Map from './Map'
import underline from '../../../assets/images/underline.svg'
import './OurFootprint.scss'

const OurFootprint = () => {

    const [city, setCity] = useState(CITY_LIST[0])

    return (
        <div className='outfootprint-wrap'>
            <div className='outfootprint-text heading-text short'>
                <p>Our <span>Footprint<img src={underline + "#svgView(preserveAspectRatio(none))"} alt="" className='outfootprint-text-underline' width="100%" /></span></p>
            </div>
            <div className='grid'>
                <div>
                    <div>{CITY_LIST.length}</div>
                    <div>Cities</div>
                </div>
                <div>
                    <div>100K+</div>
                    <div>Stores Covered</div>
                </div>
                <div>
                    <div>22+</div>
                    <div>Fulfilment Centers</div>
                </div>
                <div>
                    <div>23+</div>
                    <div>Brands</div>
                </div>
            </div>
            <div className='outfootprint'>
                <Map setCity={setCity} selectedCity={city} />
                <Contact city={city} />
            </div>
        </div>
    )
}

export default OurFootprint
